import axios from "axios";
import CacciatoreMenu from './cacciatore';
import TreVilleMenu from './treville';

const stub = false;
const mapApi = {
    stub : {
        verificaPrenotazione : '../../assets/verifica.json',
        prenota: '../../assets/prenota.json',
        menu: '../../assets/menuonline.json',
        config: '../../assets/config-online-form.json'
    },
    prod : {
        verificaPrenotazione : 'check-prenotazione',
        prenota : 'prenota-www',
        menu  : 'menuonline',
        config  : 'config-online-form',
    }

};


export default class ServiceApi {
    
    verificaPrenotazione(prenotazione) {
        return axios.post(this.getUrl('verificaPrenotazione'), prenotazione)
        .then( (response) => response.data)
         .catch(error => {
            console.log(error)
            return error.response.data;
         });
    };

    prenota(prenotazione) {


        return axios.post(this.getUrl('prenota'), prenotazione)
        .then( (response) => response.data)
         .catch(error => {
            console.log(error);
            return error.response.data;
            
         });
    };

    menu() {
        return axios.get(this.getUrl('menu'), {})
        .then( (response) => response.data)
         .catch(error => {
            console.log(error)
            return error.response.data;
         });
    };

    config() {
        return axios.get(this.getUrl('config'), {})
        .then( (response) => response.data)
         .catch(error => {
            console.log(error)
            return error.response.data;
         });
    };

    getUrl(api){
        if (stub){
            return mapApi.stub[api];
        }else{
            return "/api/?action=" + mapApi.prod[api];
        }
    }

    static getMenuMgr(config){
        if (config.titolo === 'Trattoria del Cacciatore'){
            return new CacciatoreMenu(config);
        }else{
            return new TreVilleMenu(config);
        }
    }

}

