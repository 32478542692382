import React, { useState, useEffect } from "react";
import Piatti from './piatti.js';
import Portata from './portate.js';
import Extra from './extra.js';
import _ from "lodash";
import ServiceApi from './serviceApi';
import moment from 'moment'

const Gruppi = (props) => {
    const [menuSelected, setMenuSelected] = useState(0);
    const [all, setAll] = useState(props.menu);
    const api = ServiceApi.getMenuMgr(props.config);
    const prezziMenu = props.config.prezzi;

    const onChangeMenu = (e) => {
        props.prenotazione.menuSelected = parseInt(e.target.value);
        props.prenotazione.prezzo = prezziMenu[props.prenotazione.menuSelected];
        props.prenotazione.isAnticipoGruppi = isAnticipoGruppi(props.prenotazione.giorno, props.prenotazione.orario);
        setMenuSelected(parseInt(e.target.value));

        resetPrenotazioneGruppi();

    };

    const onChangeCat = (e) => {

        let clone = Object.assign({}, all);
        setAll(clone);

        props.prenotazione.menu = [];
        _.each(_.keys(all), function (cat) {
            props.prenotazione.menu = _.concat(props.prenotazione.menu, _.filter(all[cat], { selected: true }));
        });
        props.prenotazione.isMenuCompleto = api.isMenuCompleto(props.prenotazione.menu, props.prenotazione.menuSelected);
        props.notifyChange();
    }

    const resetPrenotazioneGruppi = () => {
        _.each(_.keys(props.menu), function (cat) {
            _.each(props.menu[cat], function (piatto) {
                piatto.selected = false;
                piatto.disabled = false;
            });
        });

        props.prenotazione.menu = [];
        props.prenotazione.isMenuCompleto = false;
    }
    useEffect(() => {
        
    }, [props.menu, all]);

    /** 24 ora di anticipo */
    const isAnticipoGruppi = (giorno, orario) => {

        var iscurrentDate = moment(giorno).isSame(new Date(), 'day');
        if (iscurrentDate) {

            var str = moment(giorno).format('YYYY-MM-DD');

            var oraPrenotazione = moment(str + "T" + orario + ':00');
            var oraCorrente = moment(new Date());


            var duration = moment.duration(oraPrenotazione.diff(oraCorrente));
            var hours = duration.asHours();
            if (hours < 24) {
                return false;
            }

        }
        return true;
    };

    return (
        <div className="panel">
            <div className="alert alert-indigo fade show">
                Siete un bel gruppo e in questo caso è necessario decidere un <b>menù unico per tutto il tavolo.</b>
            </div>
            {!isAnticipoGruppi(props.prenotazione.giorno, props.prenotazione.orario) &&
                <div className="alert alert-yellow fade show">
                    ATTENZIONE! per i menu prenotati meno di 24 ore prima, non possiamo garantire la disponibilità di tutte le pietanze. Verrete contattati in caso di necessità.
                </div>
            }
            <div>
                <ul className="list-group list-group-horizontal">

                    {Array.from({ length: prezziMenu.length  }).map((item, index) => (
                        index > 0 &&
                        <li className="list-group-item orario-wrap" key={index}>
                            <input onChange={onChangeMenu} checked={props.prenotazione.menuSelected === index} type="radio" className="btn-check" name="menu" id={index} autoComplete="off" value={index} />
                            <label className="btn btn-outline-secondary" htmlFor={index}>{prezziMenu[index]}€</label>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="mb-3"></div>
            {props.menu && menuSelected > 0 ?
                <div>
                    <div className="alert alert-info fade show">
                        <Portata menu={props.prenotazione.menuSelected} config={props.config}></Portata>
                    </div>
                    <div>
                        <div>
                            <Piatti menu={all} categoria="antipastidelivery" prezzo={menuSelected} giorno={props.prenotazione.giorno} config={props.config} notifyChange={onChangeCat} />
                            <Piatti menu={all} categoria="primi" prezzo={menuSelected} giorno={props.prenotazione.giorno} config={props.config} notifyChange={onChangeCat} />
                            <Piatti menu={all} categoria="secondi" prezzo={menuSelected} giorno={props.prenotazione.giorno} config={props.config} notifyChange={onChangeCat} />
                        </div>
                    </div>
                    {props.prenotazione.isMenuCompleto ?
                        <div>
                            <div className="alert alert-lime fade show">
                                Il tuo menù è completo: puoi proseguire con la prenotazione!
                            </div>
                            <Extra prenotazione={props.prenotazione} config={props.config}></Extra>
                        </div>
                        :
                        <div className="alert alert-danger fade show">
                            Devi completare il menù per poter proseguire con la prenotazione
                        </div>
                    }

                </div>
                :
                <div className="alert alert-warning fade show">
                    Seleziona un menù e scopri cosa contiene!
                </div>
            }
        </div>

    );
}

export default Gruppi;