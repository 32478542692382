import moment from 'moment'
import _ from "lodash";


export default class CacciatoreMenu {

    static ITA_DAYS= ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];

    config={};
    validaMenu = [];

    constructor(config) {
        this.config = config;
        this.validaMenu[1] = this.valida1;
        this.validaMenu[2] = this.valida2;
        this.validaMenu[3] = this.valida3;
        this.validaMenu[4] = this.valida4;
    }

    /** Per disabilitare i piatti */
    isPiattoInMenu = (piatto, prezzo, giorno) => {
        
        if (piatto.giorni_consegna){
            //giorni_consegna
            const g = CacciatoreMenu.ITA_DAYS[moment(giorno).day()];
            if (piatto.giorni_consegna.indexOf(g.toLowerCase()) < 0){
                return false;
            }
        }

        if (prezzo === 1) {
            /*
                1 antipasto 
                1 o 2 primi
            */
            if (piatto.cat === 'antipastidelivery' && (piatto.price < 12 || piatto.price > 13 || piatto.title.toLowerCase().indexOf('ponte romano') > -1 )) {
                return false;
            }

            if (piatto.cat === 'secondi') {
                return false;
            }
            return true;
        } else if (prezzo === 2) {
            
            /*
                bocconcini di antipasto
                1 primo
                1 secondo

                i bocconcini di antipasto sono a scelta tra tutti quelli che costano meno di 11€ 

                1 primo a scelta tra tutti
                1 secondo a scelta tra tutti quelli che costano fino a 14€
            */
            if (piatto.cat === 'antipastidelivery' && piatto.price > 11 ) {
                return false;
            }

            if (( piatto.cat === 'primi') && piatto.price > 12) {
                if (piatto.title.toLowerCase().indexOf('bis di') > -1){
                    return true;
                }else{
                        return false;
                }
            } else if (piatto.cat === 'secondi' && piatto.price > 14 ) {
                return false;
            }
        } else if (prezzo === 3) {

            /*
                1 antipasto
                1 primo
                1 secondo

                antipasto a scelta tra tutti purché la somma dei loro prezzi sia uguale o inferiore a 12€
                1 primo a scelta tra tutti
                1 secondo a scelta tra tutti quelli che costano fino a 16€
            */
            if (piatto.cat === 'antipastidelivery' && piatto.price > 12 ) {
                return false;
            }

            if (piatto.cat === 'secondi' && piatto.price > 15) {
                return false;
            }
        } else if (prezzo === 4) {

            /*
                    1 antipasto
                    2 primi
                    1 secondo

                    antipasto a scelta tra tutti purché la somma dei loro prezzi sia uguale o inferiore a 16€
                    2 primi a scelta tra tutti
                    1 secondo a scelta tra tutti
            */
            
        }

        return true;

    }

    /** Per abilitare il bottone di ordine */
    isMenuCompleto = (menu, prezzo) => {
        
        const groupByCat = _.groupBy(menu, 'cat');
        let numCategorie = 3;
        if (prezzo === 1){
            numCategorie = 2;
        }
        if (_.keys(groupByCat).length < numCategorie){
                return false;
        }

        let isCompleto = false;

        if (prezzo === 1) {

            if (groupByCat['antipastidelivery'].length = 1){
                isCompleto = true;
            }

            if (groupByCat['primi'].length <= 2 ){
                isCompleto = true;
            }

            /*
            if ( (_.has(groupByCat, 'secondi') && groupByCat['secondi'].length === 1) || (_.has(groupByCat, 'antipastidelivery') &&  groupByCat['antipastidelivery'].length >= 1)){
                isCompleto = (isCompleto && true);
            }else{
                isCompleto = (isCompleto && false);
            }
            */


        } else if (prezzo === 2) {
            if (groupByCat['antipastidelivery'].length > 0){
                isCompleto = true;
            }
            if (groupByCat['primi'].length === 1 ){
                isCompleto = (isCompleto && true);
            }else{
                isCompleto = (isCompleto && false);
            }

            if (groupByCat['secondi'].length === 1){
                isCompleto = (isCompleto && true);
            }else{
                isCompleto = (isCompleto && false);
            }
        } else if (prezzo === 3) {

            if (groupByCat['antipastidelivery'].length > 0){
                isCompleto = true;
            }
            if (groupByCat['primi'].length === 1 ){
                isCompleto = (isCompleto && true);
            }else{
                isCompleto = (isCompleto && false);
            }

            if (groupByCat['secondi'].length === 1){
                isCompleto = (isCompleto && true);
            }else{
                isCompleto = (isCompleto && false);
            }
        } else if (prezzo === 4) {
            
            if (groupByCat['antipastidelivery'].length > 0){
                isCompleto = true;
            }
            if (groupByCat['primi'].length === 2){
                isCompleto = (isCompleto && true);
            }else{
                isCompleto = (isCompleto && false);
            }

            if (groupByCat['secondi'].length === 1 ){
                isCompleto = (isCompleto && true);
            }else{
                isCompleto = (isCompleto && false);
            }

            
        }

        return isCompleto;

    }


    /** disabilita gli altri piatti a seguito di una selezione */
    valida1 = (piatto, countByCat, piatti) => {

        if (piatto.cat === 'primi') {
            //i 2 primi a scelta tra tutti purché la somma dei prezzi dei due sia uguale o inferiore a 24€
            return  CacciatoreMenu.hasAntipasto(piatto, 25, piatti);
        } else if (piatto.cat === 'antipastidelivery' && countByCat['antipastidelivery'] >= 1) {
            return true;
        }

        return false;
    }
    
    valida2 = (piatto, countByCat, piatti) => {

        if (piatto.cat === 'primi'  && countByCat['primi'] >= 1) {
            return true;
        } else if (piatto.cat === 'secondi' && countByCat['secondi'] >= 1) {
            return true;
        } else if (piatto.cat === 'antipastidelivery') {
            // la somma dei loro prezzi sia inferiore a 15€
            return CacciatoreMenu.hasAntipasto(piatto, 15, piatti);
        }

        return false;
    }
    
    valida3 = (piatto, countByCat, piatti) => {
        if (piatto.cat === 'primi' && countByCat['primi'] >= 1) {
            return true;
        } else if (piatto.cat === 'secondi' && countByCat['secondi'] >= 1) {
            return true;
        } else if (piatto.cat === 'antipastidelivery') {
            // antipasto a scelta tra tutti purché la somma dei loro prezzi sia uguale o inferiore a 12€
            return CacciatoreMenu.hasAntipasto(piatto, 13, piatti);
        }
        return false;
    }
    
    valida4 = (piatto, countByCat, piatti) => {
        if (piatto.cat === 'primi' && countByCat['primi'] >= 2) {
            return true;
        } else if (piatto.cat === 'secondi' && countByCat['secondi'] >= 1) {
            return true;
        } else if (piatto.cat === 'antipastidelivery') {
            //antipasto a scelta tra tutti purché la somma dei loro prezzi sia uguale o inferiore a 16€
            return CacciatoreMenu.hasAntipasto(piatto, 17, piatti);
        }
        return false;//
    }

    hasExtra = (piatto, countByCat, categoria, piatti) => {
        let myInstance = this;
        if (countByCat[categoria] >= 2) {
            return true;
        } else if (countByCat[categoria] === 1) {
            // se il bollito è quello corrente gli altri sono disabilitati
            // cioè quando ce n'è uno e il bollito è da disabilitare
            if (this.isExtra(piatto.title)) {
                return true;
            }
            // se il bollito è selezionato tutto il resto è da disabilitare
            const bollito = _.filter(piatti, function (p) {
                return p.selected && myInstance.isExtra(p.title);
            });
            if (bollito.length > 0) {
                return true;
            }
        }
        return false;
    }

    isExtra = (titolo) => {
        return  (_.filter(this.config.extra, function(e){
            return titolo.toLowerCase().indexOf(e) > -1;
        }).length > 0);
    }
    
    static hasAntipasto = (piatto, prezzoMassimo, piatti) => {
        let total = _.sumBy(piatti, function (p) {
            return p.selected ? p.price : 0;
        });

        if (total >= prezzoMassimo || (total + piatto.price) >= prezzoMassimo) {
            return true;
        }
        return false;
    }

    static isPresente = (titolo, menu) => {
        return _.find(menu, function(piatto){
              return piatto.title.toLowerCase().indexOf(titolo) > -1;  
        }) !== undefined;
    }

}