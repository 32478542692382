import React, { useState, useEffect, useRef } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ServiceApi from './serviceApi';
import moment from 'moment'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Gruppi from './gruppi.js'
import _ from "lodash";
import { useSearchParams } from "react-router-dom";
import Facebook from './facebook';

import { registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it';
registerLocale('it', it)

const RegisterV3 = () => {

	const CENA = 'cena';

	const api = new ServiceApi();

	const [searchParams, setSearchParams] = useSearchParams();

	const buttonSubmitRef = useRef(null);//per evitare doppio click
	const [prenotazione, setPrenotazione] = useState({ intolleranze: [], motivo: [] });
	const [isLoading, setIsLoading] = useState(false);
	const [isAgreement, setIsAgreement] = useState(false);
	const [isPrenotazioneConfermata, setIsPrenotazioneConfermata] = useState(false);
	const [isPrenotazioneRifiutata, setIsPrenotazioneRifiutata] = useState(false);
	const [specials, setSpecials] = useState({});

	const [giorno, setGiorno] = useState();
	const [orario, setOrario] = useState("");
	const [persone, setPersone] = useState(0);
	const [note, setNote] = useState("");
	const [nome, setNome] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");

	const [config, setConfig] = useState();
	const [menu, setMenu] = useState();
	const [piatti, setPiatti] = useState([]);
	const [message, setMessage] = useState();


	//------evento con parametri di input------

	const [isEvento, setIsEvento] = useState(false); //numero minimo e ruppi
	const [isNoPhoto, setIsNoPhoto] = useState(false); //grafica
	const [dayOfWeek, setDayOfWeek] = useState(); // tutti i venerdì
	const [dayOfMonth, setDayOfMonth] = useState(); // giorno specifico
	const [servizio, setServizio] = useState(); // pranzo / cena
	const [eventTitle, setEventTitle] = useState(); // titolo evento
	
	//------------

	useEffect(() => {
	}, [prenotazione, giorno, persone, orario, specials, isAgreement]);

	useEffect(() => {
	
		api.config().then((data) => {

			
			setSpecials(data.specials);

			//titolo form
			const paramTitle = searchParams.get("title");
			var formTitle = 'Prenota';
			if (paramTitle && data.eventi[paramTitle]){
				// parametro in input corrisponde a chiave delle label
				data.evento = data.eventi[paramTitle]; //aggiungo evento selezionato
				formTitle = data.evento.name;

			}
			setConfig(data);
			setEventTitle(formTitle);
		});

		//parametri in query string per evento
		setIsNoPhoto(searchParams.get("nophoto") === 'true' );
		setDayOfWeek(searchParams.get("dow"));
		setDayOfMonth(searchParams.get("day"));
		setServizio(searchParams.get("pc"));
		setIsEvento( searchParams.get("dow") || searchParams.get("day") || searchParams.get("title"));
		

	}, []);

	const verificaPrenotazione = (payload) => {

		if (payload.giorno !== undefined && payload.giorno !== null
			&& payload.peoples > 0 && payload.orario !== undefined && payload.orario !== '') {
			setIsLoading(true);
			payload.giorno = moment(payload.giorno).format('YYYY-MM-DD');

			if (isEvento){
				payload.serviziocustom = 'ev'; //ev_cena
			}

			api.verificaPrenotazione(payload).then((data) => {
				setIsLoading(false);
				prenotazione.disponibile = data.status;
				prenotazione.turni = data.turni;
				setPrenotazione(prenotazione);
				setMessage(config.messages[payload.giorno]);


				setIsPrenotazioneConfermata(false);
				setIsPrenotazioneRifiutata(false);
			});
		}
	};

	const handleChangeDate = (data) => {

		delete prenotazione.disponibile;
		prenotazione.giorno = data;
		setPrenotazione(prenotazione);

		setGiorno(data);
		verificaPrenotazione(prenotazione);

	};

	const onChangeOrario = (e) => {

		delete prenotazione.disponibile;
		prenotazione.orario = e.target.value;
		setPrenotazione(prenotazione);

		setOrario(e.target.value);
		verificaPrenotazione(prenotazione);
	};

	const onChangePersone = (e) => {

		delete prenotazione.disponibile;
		prenotazione.peoples = parseInt(e.target.value);

		if (isEvento){
			prenotazione.isMenuCompleto = true;
		}
		

		setPrenotazione(prenotazione);

		setPersone(parseInt(e.target.value));
		verificaPrenotazione(prenotazione);

		if (prenotazione.peoples > config.minGruppo && !isEvento) {
			loadMenuPerGruppi();
		}
	};


	const onChangeAgreement = (e) => {
		setIsAgreement(e.target.checked);
	};

	const handleOnChangeNote = (e) => {
		setNote(e.target.value);
	};
	const handleOnChangeNome = (e) => {
		setNome(e.target.value);
	};
	const handleOnChangeMail = (e) => {
		setEmail(e.target.value);
	};


	const handleChangeIntolleranze = (e) => {
		var value = e.target.value;
		if (e.target.checked) {
			prenotazione.intolleranze.push(value);
		} else {
			prenotazione.intolleranze = prenotazione.intolleranze.filter(data => data !== value);
		}
	};

	const handleChangeMotivo = (e) => {
		var value = e.target.value;
		if (e.target.checked) {
			prenotazione.motivo.push(value);
		} else {
			prenotazione.motivo = prenotazione.motivo.filter(data => data !== value);
		}
	};

	/**
	 * Click bottone prenota
	 * @param {*} event 
	 */
	const handleSubmit = (event) => {
		
		event.preventDefault();
		
		//console.log(buttonSubmitRef.current.disabled);
		if (buttonSubmitRef.current.disabled){
			console.log('return--------------');
			return;
		}
		buttonSubmitRef.current.disabled = true;

		//console.log('submit '+ new Date());
		
		prenotazione.giorno = moment(giorno).format('YYYY-MM-DD');
		prenotazione.note = note;
		prenotazione.name = nome;
		prenotazione.phone = phone;
		prenotazione.email = email;

		if (prenotazione.menu && prenotazione.menuSelected > 0 && config.prodottiMenu){
			// aggiungo i dolci
			prenotazione.menu = _.concat(prenotazione.menu, config.prodottiMenu[prenotazione.menuSelected.toString()] );
        }
		
		if (isEvento){
			prenotazione.motivo.push(config.evento.name);
		}

		setIsPrenotazioneConfermata(false);
		setIsPrenotazioneRifiutata(false);
		setIsLoading(true);
		api.prenota(prenotazione).then((data) => {
			setIsLoading(false);
			if (data.confirmed) {
				setIsPrenotazioneConfermata(true);

				if (typeof window !== "undefined") {
					if (window.fbq != null) { 
					  window.fbq('track', 'PrenotazioneOk')
					}
				}

			} else {
				setIsPrenotazioneRifiutata(true);
			}
			buttonSubmitRef.current.disabled = false;

		});

	};

	/** 1 ora di anticipo */
	const isOrarioValido = (orario) => {

		var iscurrentDate = moment(giorno).isSame(new Date(), 'day');
		if (iscurrentDate) {

			var str = moment(giorno).format('YYYY-MM-DD');

			var oraPrenotazione = moment(str + "T" + orario + ':00');
			var oraCorrente = moment(new Date());


			var duration = moment.duration(oraPrenotazione.diff(oraCorrente));
			var hours = duration.asHours();
			if (hours < 1) {
				return false;
			}

		}
		return true;
	};

	const loadMenuPerGruppi = () => {

		setIsLoading(true);

		api.menu().then((data) => {
			var filtered = _.reject(data.menu, function (e) {
				return e.cat === 'piatti-del-giorno' || e.cat === 'contornidelivery' || e.cat === 'dolci' || !e.showonline;
			});
			var groupedByCategory = _.groupBy(filtered, 'cat');
			setMenu(groupedByCategory);
			setIsLoading(false);
		});

	}

	const onChangeGruppi = () => {
		let clone = Object.assign({}, prenotazione);
		setPrenotazione(clone);
	}

	/*
		abilito solo il giorno in input (param query string)
	*/
	const filterDatePassed = (date) => {
		if (dayOfWeek){
			let dayOfWeekPassed = moment(date).day();

			let listDaysOfWeek = getDaysFromFilter();
			return listDaysOfWeek.includes(dayOfWeekPassed);
		}else{
			return true;
		}

	};

	const getDaysFromFilter = () => {
		return dayOfWeek.split(',').map( dayStr => parseInt(dayStr) );
	};

	    /**
     * restituisce gli orari in cui è possibile prenotare prendendoli dalla configurazione 
     * e gestendo il caso della domenica
     */
	const getOrariPrenotazioni = (servizio) =>{
		var cfg = config.orari;
		var orariList = cfg[servizio];
		if (giorno){
			var giornoCorrentNum = moment(giorno).isoWeekday();  // 7 domenica
			if (cfg.extra && cfg.extra[giornoCorrentNum] &&  cfg.extra[giornoCorrentNum][servizio]){
				return cfg.extra[giornoCorrentNum][servizio];
			}
		}
		return orariList;
	};

	const getHighlightDates = () => {
		if (dayOfWeek){
			//let dayToBeSelected = parseInt(dayOfWeek);

			let listDaysOfWeek = getDaysFromFilter();

			var listAllDays =[]; 
			listDaysOfWeek.forEach(function(dayToBeSelected){

				var weekDayToFind = moment().day(dayToBeSelected).weekday(); //change to searched day name

				var searchDate = moment(); //now or change to any date
				while (searchDate.weekday() !== weekDayToFind){ 
					searchDate.add(1, 'day'); 
				}
	
	
				var start = searchDate, // Sept. 1st
					end   = moment(searchDate).add(100, 'days'), // Nov. 2nd
					day   = dayToBeSelected;                    // Sunday
	
				var result = [start];
				var current = start.clone();
	
				while (current.day(7 + day).isBefore(end)) {
					result.push(current.clone());
				}
	
				var resDateObj = result.map(m => m.toDate());
				listAllDays = listAllDays.concat(resDateObj);
			});

			return listAllDays;
		}else{
			return [];
		}

	};


	

	return (
		<div >
		{config &&
		<div className="register register-with-news-feed">
			{!isNoPhoto &&
			<div className="news-feed">
				<div className="news-image" style={{ backgroundImage: `url(${config.sfondo})` }}></div>
				<div className="news-caption">
					<h4 className="caption-title">{config.titolo}</h4>
					<p>
					{config.label.sottotitolo}
					</p>
				</div>
			</div>
			}
			{isPrenotazioneConfermata ?
				<div className={"register-container " + (isNoPhoto ? "register-container-full" : "")}>
					<div className="register-header mb-15px ">
						<img alt="logo" src={config.logo}></img>	
					</div>
					<div className="register-header mb-25px h1">
						<div className="mb-1">Prenotazione confermata!</div>
						<small className="d-block fs-15px lh-16"></small>
					</div>
					<div className="register-content">
						<div className="alert alert-success fade show">
							<h2>Grazie per avere prenotato un tavolo! </h2>
								<h3>{config.label.confermaSottotitolo}</h3>
						</div>
						<div className="mt-5">
							<p className="text-center"><h3>Nel frattempo puoi dare un'occhiata al menu <a href={config.linkMenu}>qui</a>.</h3></p>
						</div>
					</div>
				</div>
				:
				<div className={"register-container " + (isNoPhoto ? "register-container-full" : "")}>
					{!isNoPhoto &&
					<div>
						<div className="register-header mb-15px ">
							<img alt="logo" src={config.logo}></img>	
						</div>
						<div className="register-header mb-25px h1">
							<div className="mb-1">{eventTitle}</div>
							<small className="d-block fs-15px lh-16">Prenota un tavolo e verifica immediatamente la disponibilità.</small>
						</div>
					</div>
					}
					<div className="register-content">
						<form data-parsley-validate="true" onSubmit={handleSubmit} className="fs-13px">

							<div className="mb-3">
								<label className="col-lg-4 col-form-label">Scegli il giorno</label>
								<div className="col-md-12 col-lg-8">
									<DatePicker filterDate={filterDatePassed} highlightDates={getHighlightDates()} selected={giorno} inline locale="it" minDate={moment().toDate()} onChange={(date) => handleChangeDate(date)}  />
								</div>
							</div>

							<div className="mb-3">
								<label className="mb-2">Orario <span className="text-danger">*</span></label><br />
								{ (!servizio || servizio === 'pranzo') &&
								<ul className="list-group list-group-horizontal">
									{getOrariPrenotazioni('pranzo').map((item) => (
										isOrarioValido(item) &&
										<li className="list-group-item orario-wrap" key={item} >
											<input onChange={onChangeOrario} checked={prenotazione.orario === item} type="radio" className="btn-check" name="options"  id={item} autoComplete="off" value={item} />
											<label className="btn btn-outline-secondary" htmlFor={item}>{item}</label>
										</li>
									))}
								</ul>
								}
								
								<div className="mb-1"></div>
								{(!servizio || servizio === 'cena') &&
								<ul className="list-group list-group-horizontal">
									{getOrariPrenotazioni('cena').map((item) => (
										isOrarioValido(item) &&
										<li className="list-group-item orario-wrap" key={item} >
											<input onChange={onChangeOrario} checked={prenotazione.orario === item} type="radio" className="btn-check" name="optionscena"  id={item} autoComplete="off" value={item} />
											<label className="btn btn-outline-secondary" htmlFor={item}>{item}</label>
										</li>
									))}
									
								</ul>
								}
							</div>


							<div className="mb-3">
								<label className="mb-2">Numero di persone <span className="text-danger">*</span></label><br />

								<ul className="list-group list-group-horizontal">

								{Array.from({ length:  9  }).map((it, idxPers) => (
									idxPers > 0 && (!isEvento || (isEvento && idxPers >= config.evento.minPersone) ) &&
									<li className="list-group-item orario-wrap" key={"pl"+idxPers}>
										<input onChange={onChangePersone} checked={persone === idxPers} type="radio" className="btn-check" name="persone" id={"p"+idxPers} autoComplete="off" value={idxPers} />
										<label className="btn btn-outline-secondary" htmlFor={"p"+idxPers}>{idxPers}</label>
									</li>
								))}
								</ul>

								<div className="mt-2">
									<label className="mb-2">Siamo un gruppo&nbsp;&nbsp;</label>
									<select className="form-select" style={{'display':'inline', 'width':'auto'}} onChange={onChangePersone} value={persone}><option></option>
									{Array.from({ length: config.maxGruppo - 8  }).map((it, index) => <option value={9+index} key={index}>{9+index}</option>
									)}
									</select>
								</div>
							</div>

					{isLoading &&
						<div className="alert alert-secondary fade show">
							Verifica della disponibilità in corso...
						</div>
					}

					{prenotazione.disponibile !== undefined && !prenotazione.disponibile && prenotazione.turni.length ==0 &&
						<div className="alert alert-danger fade show">
							<span dangerouslySetInnerHTML={{__html: config.label.nonDisponibile}} />
						</div>
					}
					{prenotazione.disponibile !== undefined && !prenotazione.disponibile && prenotazione.turni.length > 0 &&
						<div className="alert alert-warning fade show">
							<span dangerouslySetInnerHTML={{__html: config.label.disponibileAltraFascia + prenotazione.turni.join(' oppure ') }} />
						</div>
					}
					{prenotazione.disponibile !== undefined && prenotazione.disponibile &&
						<div className="alert alert-success fade show">
							C'è un tavolo disponibile, puoi procedere con la prenotazione.
						</div>
					}
					{message !== undefined &&
						<div className="alert alert-warning fade show">
							<b>{message}</b>
						</div>
					}

					{prenotazione.disponibile &&
						<div>
							{!isEvento && prenotazione.peoples > config.minGruppo && menu &&
								<Gruppi menu={menu} prenotazione={prenotazione} piatti={piatti} notifyChange={onChangeGruppi} config={config}></Gruppi>
							}

							<div className="mb-3">
								<label className="form-label col-form-labelmb-2">Nome <span className="text-danger">*</span></label>
								<input type="text" className="form-control fs-13px" required value={nome} onChange={handleOnChangeNome} />
							</div>
							<div className="mb-3">
								<label className="form-label col-form-labelmb-2">Telefono <span className="text-danger">*</span></label>
								<PhoneInput
									required
									countryCallingCodeEditable={false}
									international={false}
									defaultCountry="IT"
									country="IT"
									value={phone}
									onChange={setPhone} 
									limitMaxLength={true}/>
							</div>
							<div className="mb-3">
								<label className="form-label col-form-label mb-2">Email <span className="text-danger">*</span></label>
								<input type="email" className="form-control fs-13px" required value={email} onChange={handleOnChangeMail} />
							</div>

							<div className="mb-3">
								<label className="form-label col-form-label col-md-4">Note / richieste</label>
								<div className="col-md-12">
									<textarea onChange={handleOnChangeNote} value={note} maxLength="300" className="form-control" rows="3"></textarea>
								</div>
							</div>


							<div className="mb-3">
								<label className="form-label col-form-label mb-2">Indicazioni alimentari</label>
								{specials.intolleranze.sort((a, b) => a.name.localeCompare(b.name)).map((item) => (
									<div className="form-check mb-2" key={item.id}>
										<input onChange={handleChangeIntolleranze} className="form-check-input" type="checkbox" id={item.id} value={item.name} />
										<label className="form-check-label" htmlFor={item.id}>
											{item.name}
										</label>
									</div>

								))}
							</div>

							
							<div className="mb-3">
								<label className="form-label col-form-label mb-2">Motivo</label>
								{specials.motivo.sort((a, b) => a.name.localeCompare(b.name)).map((item) => (
									<div className="form-check mb-2" key={item.id}>
										<input onChange={handleChangeMotivo} className="form-check-input" type="checkbox" id={item.id} value={item.name} />
										<label className="form-check-label" htmlFor={item.id}>
											{item.name}
										</label>
									</div>

								))}
							</div>
							

							<div className="form-check mb-4">
								<input onChange={onChangeAgreement} value={prenotazione.agreement} className="form-check-input" type="checkbox" id="agreementCheckbox" />
								<label className="form-check-label" htmlFor="agreementCheckbox">
									Procedendo, tu confermi di letto la nostra  <a href={config.linkPrivacy}>Privacy Policy</a>, sui dati e <a href={config.linkCookie}>l'utilizzo dei cookie</a>. <span className="text-danger">*</span>
								</label>
							</div>

							{isPrenotazioneRifiutata &&
								<div className="alert alert-danger fade show">
									Purtroppo non ci sono più tavoli disponibili per il giorno che hai scelto.
								</div>
							}

							<div className="mb-4">
								<button ref={buttonSubmitRef} disabled={!isAgreement || (prenotazione.peoples > config.minGruppo && !prenotazione.isMenuCompleto) || !prenotazione.disponibile} type="submit" className="btn btn-primary d-block w-100 btn-lg h-45px fs-13px">Prenota</button>
							</div>
							<p className="text-center"><em>{config.label.animali}</em></p>
						</div>
					}
					<hr className="bg-gray-600 opacity-2" />
					{!isNoPhoto &&
					<p className="text-center text-gray-600">
						&copy; {config.copyright}
					</p>
					}
				</form>
					</div>
				</div >
			}
		</div >
		}
		{config && config.facebookPixelId &&
		<Facebook config={config}></Facebook>
		}
		</div >
	);

}

export default RegisterV3;