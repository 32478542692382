import _ from "lodash";
import React, { useState, useEffect } from "react";

const Extra = ({ prenotazione, config }) => {

    const extraList = config.addons;

    useEffect(() => {
        console.log(extraList);
    }, []);


    const handleChangeExtra = (e, addon) => {
        if (!prenotazione.addons){
            prenotazione.addons = [];

        }

        var value = e.target.value;
		if (e.target.checked) {
			prenotazione.addons.push(value);
		} else {
			prenotazione.addons = prenotazione.addons.filter(data => data !== value);
		}
    }

    return (
        <div>
            {extraList &&
                <div className="mb-3">
                    <h3 className="mb-2" >Puoi ordinare anche</h3>
                    {extraList.map((item,i) => (

                        <div className="form-check mb-2" key={i + '_' }>
                            <input  checked={item.selected} onChange={(e) => handleChangeExtra(e, item)} className="form-check-input" type="checkbox" id={item.id} value={item.nome} />
                            <label className="form-check-label" htmlFor={item.id}>{item.descrizione}</label>
                        </div>

                    ))}
                </div>
            }
        </div>


    );

}

export default Extra;