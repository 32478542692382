import React, { useEffect } from "react";

const Portata = ({ menu, config }) => {

    useEffect(() => {

    }, [menu]);

    return (
        <ul>
            {config.menu[menu-1].piatti.map((item) => (
                <li key={item}>{item}</li>
            ))}
        </ul>
    );

}

export default Portata;